<template>
  <div id="user_form" v-if="bannerCategoryData">
    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ bannerCategoryData.id == 0 ? "Add" : "Edit" }} Banner Category
      </h3>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <!-- Form: Personal Info Form -->
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">

            <!-- Field: Name -->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Name"
                  label-for="name"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <b-form-input
                    id="name"
                    v-model="bannerCategoryData.name"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter Banner Category name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
           
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Description"
                rules=""
              >
                <b-form-group
                  class=""
                  label="Description"
                  label-for="description"
                  label-cols-sm="3"
                  label-cols="12"
                >
                  <b-form-input
                    id="description"
                    v-model="bannerCategoryData.description"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter Banner Category description"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Button: Submit & Reset Button.-->

          <hr />
          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  methods: {
    onSubmit() {
      console.log("submit data", this.bannerCategoryData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-banner-categories/addBannerCategory", this.bannerCategoryData)
            .then((response) => {
              this.loading = false;

              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$router.replace("/banner/categories");
            })
            .catch((error) => {
              this.loading = false;

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  initTrHeight() {
    this.trSetHeight(null);
    this.$nextTick(() => {
      this.trSetHeight(this.$refs.form.scrollHeight);
    });
  },
  props: {
    bannerCategoryData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    console.log(props.bannerCategoryData);
    const loading = ref(false);

    const resetData = () => {
      emit("refetch-data");
    };
    const resetbannerCategoryData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetbannerCategoryData
    );

    onMounted(() => {});

    return {
      loading,
      resetData,
      refFormObserver,
      getValidationState,
      resetForm,
      resetbannerCategoryData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.teamleader_selected {
  background: #110f6a;
  padding: 3px 10px;
  border-radius: 3px;
  color: white;
}

.addParent {
  position: relative;
}

.addChildren {
  position: absolute;
  right: -5px;
  bottom: 35%;
  padding: 2px !important;
  background: #a03071;
  border-radius: 15px;
  color: white;
}

.removeChildren {
  position: absolute;
  right: -5px;
  bottom: 35%;
  padding: 2px !important;
  background: #d14f4f;
  border-radius: 15px;
  color: white;
}
</style>
