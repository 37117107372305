<template>
  <component :is="bannerCategoryData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="bannerCategoryData === undefined">
      <h4 class="alert-heading">Error fetching banner category data</h4>
      <div class="alert-body">
        No banner category found with this banner category id. Check
        <b-link class="alert-link" :to="{ name: 'banner-category-list' }">
          Banner Category List
        </b-link>
        for other banner categorys.
      </div>
    </b-alert>

    <b-tabs v-if="bannerCategoryData" class="tabs-primary" pills>
      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Banner Category</span>
        </template>
        <banner-categories-tab-information
          :banner-category-data="bannerCategoryData"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import bannerCategoryStoreModule from "../bannerCategoryStoreModule";
import BannerCategoriesTabInformation from "./BannerCategoriesTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    BannerCategoriesTabInformation,
  },
  setup() {
    const bannerCategoryData = ref(null);
    const PRODUCT_CATEGORY_APP_STORE_MODULE_NAME = "app-banner-categories";

    const banner_category = {
      id: 0,
      name: null,
      description: null
    };

    // Register module
    if (!store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
      store.registerModule(
        PRODUCT_CATEGORY_APP_STORE_MODULE_NAME,
        bannerCategoryStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_CATEGORY_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "banner-category-create") {
        bannerCategoryData.value = banner_category;
      } else {
        store
          .dispatch("app-banner-categories/fetchBannerCategory", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            console.log("get banner categpry response", response);
            bannerCategoryData.value = response.data.banner_category;
          })
          .catch((error) => {
            console.log("error when fetching banner category", error);
            if (error.response.status === 404) {
              bannerCategoryData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchOption();
    }),
      refetchData();

    return {
      bannerCategoryData,
      refetchData,
    };
  },
};
</script>

<style></style>
