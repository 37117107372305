import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBannerCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/banner_categories/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBannerCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/banner_categories`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addBannerCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/banner_categories", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
